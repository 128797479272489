export const winnersOptions = {
	dots: true,
	responsive: [
		{
			breakpoint: 0,
			settings: {
				dots: true,
				slidesToShow: 3,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 4,
				dots: true,
			}
		}
	]
}

export const productsAboutOptions = {
	dots: true,
	responsive: [
		{
			breakpoint: 0,
			settings: {
				dots: true,
				slidesToShow: 1,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 5,
				dots: true,
			}
		}
	]
}

export const aboutOptions = {
	dots: true,
	responsive: [
		{
			breakpoint: 0,
			settings: {
				unagile: false,
				dots:true,
				slidesToShow: 1,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 5,
				dots: true,
				unagile: true,
			}
		}
	]
}

export const descriptorOptions = {
	dots: false,
	navButtons: false,
	autoplay: true,
	infinite: true,
		responsive: [
		{
			breakpoint: 0,
			settings: {
				dots: false,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 1,
				dots: false,
			}
		}
	]
}

export const certificatesOptions = {
	dots: false,
		navButtons: false,
		responsive: [
		{
			breakpoint: 0,
			settings: {
				navButtons: true,
				dots: true,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 4,
				navButtons: false,
				dots: false,
			}
		}
	]
}

export const prizesOptions = {
	dots: false,
	infinite: true,
		responsive: [
		{
			breakpoint: 0,
			settings: {
				autoplay: false,
				dots: true,
				navButtons: true,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 3,
				autoplay: true,
				dots: false,
				navButtons: false,
			}
		}
	]
}

export const productsOptions = {
	dots: false,
		responsive: [
		{
			breakpoint: 0,
			settings: {
				dots: true,
			}
		},
		{
			breakpoint: 992,
			settings: {
				dots: false,
			}
		}
	]
}

export const productsTwoOptions = {
	dots: false,
		responsive: [
		{
			breakpoint: 0,
			settings: {
				dots: true,
			}
		},
		{
			breakpoint: 992,
			settings: {
				dots: true,
			}
		}
	]
}

export const merchOptions = {
	dots: false,
		navButtons: false,
		responsive: [
		{
			breakpoint: 0,
			settings: {
				navButtons: true,
				dots: true,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 3,
				navButtons: false,
				dots: false,
			}
		}
	]
}

export const instrOptions = {
	dots: false,
		navButtons: false,
		responsive: [
		{
			breakpoint: 0,
			settings: {
				slidesToShow: 2,
				navButtons: true,
				dots: true,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 4,
				navButtons: false,
				dots: false,
			}
		}
	]
}

export const carousel = {
	dots: false,
		descriptor: [
		{
			key: 1,
			image: {
				src: 'prize-100000.svg',
				alt: '100000'
			}
		},
		{
			key: 2,
			image: {
				src: 'prize-25.svg',
				alt: '25'
			}
		}
	],
		certificates : [
		{
			key: 1,
			image: {
				src: 'cerificate-dm.svg',
				alt: 'Сертификат Детский мир',
				caption: 'Для развития талантов'
			}
		},
		{
			key: 2,
			image: {
				src: 'certificate-ozon.svg',
				alt: 'Сертификат OZON',
				caption: 'Для творческих побед'
			}
		},
		{
			key: 3,
			image: {
				src: 'certificate-mvideo.svg',
				alt: 'Сертификат Mvideo',
				caption: 'Для прокачки супернавыков'
			}
		},
		{
			key: 4,
			image: {
				src: 'certificate-sportmaster.svg',
				alt: 'Сертификат Спортмастер',
				caption: 'Для новых спортивных рекордов'
			}
		}
	],
		prizes: [
		/*{
			key: 1,
			image: {
				src: '100000.png',
				alt: '100000',
				caption: 'На обучение'
			}
		},*/
		{
			key: 2,
			image: {
				src: 'watch.png',
				alt: 'Детские смарт-часы',
				caption: 'Десткие смарт часы'
			}
		},
		{
			key: 3,
			image: {
				src: 'bmx.png',
				alt: 'BMX',
				caption: 'BMX'
			}
		},
		{
			key: 4,
			image: {
				src: 'tablet.png',
				alt: 'Планшет',
				caption: 'Планшет'
			}
		},
		{
			key: 5,
			image: {
				src: 'laptop.png',
				alt: 'Ноутбук',
				caption: 'Ноутбук'
			}
		},
		{
			key: 6,
			image: {
				src: 'segway.png',
				alt: 'Сигвей',
				caption: 'Сигвей'
			}
		}
	],
		prizess: [
		{
			key: 1,
			image: {
				src: '100000.png',
				alt: '100000',
				caption: 'На обучение'
			}
		},
		{
			key: 2,
			image: {
				src: '100000.png',
				alt: '100000',
				caption: 'На обучение'
			}
		},
		{
			key: 3,
			image: {
				src: '100000.png',
				alt: '100000',
				caption: 'На обучение'
			}
		},
		{
			key: 4,
			image: {
				src: '100000.png',
				alt: '100000',
				caption: 'На обучение'
			}
		},
		{
			key: 5,
			image: {
				src: '100000.png',
				alt: '100000',
				caption: 'На обучение'
			}
		},
		{
			key: 6,
			image: {
				src: '100000.png',
				alt: '100000',
				caption: 'На обучение'
			}
		}
	],
		productsJuice: [
			{
				key: 2,
				type: 'juice',
				image: {
					src: 'juice-type-2.png',
					alt: 'Сок#2'
				},
				caption: 'Яблоко',
				ml: '200',
				description: 'Состав: яблочный сок, витаминный комплекс «Active kid» (B2, B3, B5, B12)',
				vitamines: {
					0: {
						'caption': 'b2',
						'value': '18%',
						'color': '#44C7F4',
						'fontSize': '27px',
					},
					1: {
						'caption': 'b3',
						'value': '24%',
						'color': '#61A2D7',
						'fontSize': '27px',
					},
					2: {
						'caption': 'b5',
						'value': '30%',
						'color': '#A6CF38',
						'fontSize': '27px',
					},
					3: {
						'caption': 'b12',
						'value': '24%',
						'color': '#39B54A',
						'fontSize': '27px',
					},
					4: {
						'caption': 'ККАл/\nКДЖ',
						'value': '76/323',
						'color': '#1D5533',
						'fontSize': '14px',
					},
				},
		},
			{
				type: 'juice',
				key: 5,
				image: {
					src: 'about_product_1_5.png',
					alt: 'Сок#2'
				},
				caption: 'Яблоко-Вишня',
				ml: '200',
				description: 'Состав: яблочный сок, вишневый сок, витаминный комплекс «Active Kid» (B2, B3, B5, B12)',
				vitamines: {
					0: {
						'caption': 'b2',
						'value': '20%',
						'color': '#44C7F4',
						'fontSize': '27px',
					},
					1: {
						'caption': 'b3',
						'value': '26%',
						'color': '#61A2D7',
						'fontSize': '27px',
					},
					2: {
						'caption': 'b5',
						'value': '30%',
						'color': '#A6CF38',
						'fontSize': '27px',
					},
					3: {
						'caption': 'b12',
						'value': '24%',
						'color': '#39B54A',
						'fontSize': '27px',
					},
					4: {
						'caption': 'ККАл/\nКДЖ',
						'value': '80/340',
						'color': '#1D5533',
						'fontSize': '14px',
					},
				},
			},
			{
				type: 'juice',
				key: 5,
				image: {
					src: 'persik_juice.png',
					alt: 'Сок#3'
				},
				caption: 'Яблоко-Персик',
				ml: '200',
				description: 'Состав: яблочный сок, персиковое пюре, яблочное пюре, витаминный комплекс «Active Kid» (B2, B3, B5, B12)',
				vitamines: {
					0: {
						'caption': 'b2',
						'value': '18%',
						'color': '#44C7F4',
						'fontSize': '27px',
					},
					1: {
						'caption': 'b3',
						'value': '28%',
						'color': '#61A2D7',
						'fontSize': '27px',
					},
					2: {
						'caption': 'b5',
						'value': '30%',
						'color': '#A6CF38',
						'fontSize': '27px',
					},
					3: {
						'caption': 'b12',
						'value': '24%',
						'color': '#39B54A',
						'fontSize': '27px',
					},
					4: {
						'caption': 'ККАл/\nКДЖ',
						'value': '80/340',
						'color': '#1D5533',
						'fontSize': '14px',
					},
				},
			},
			{
				type: 'juice',
				key: 5,
				image: {
					src: 'multifruit_juice.png',
					alt: 'Сок#3'
				},
				caption: 'Мультифрукт',
				ml: '200',
				description: 'Состав: яблочный сок, ,банановое пюре, апельсиновый сок, ананасовый сок, витаминный комплекс «Active Kid» (B2, B3, B5, B12)',
				vitamines: {
					0: {
						'caption': 'b2',
						'value': '18%',
						'color': '#44C7F4',
						'fontSize': '27px',
					},
					1: {
						'caption': 'b3',
						'value': '28%',
						'color': '#61A2D7',
						'fontSize': '27px',
					},
					2: {
						'caption': 'b5',
						'value': '30%',
						'color': '#A6CF38',
						'fontSize': '27px',
					},
					3: {
						'caption': 'b12',
						'value': '24%',
						'color': '#39B54A',
						'fontSize': '27px',
					},
					4: {
						'caption': 'ККАл/\nКДЖ',
						'value': '84/357',
						'color': '#1D5533',
						'fontSize': '14px',
					},
				},
			},
			{
				type: 'juice',
				key: 5,
				image: {
					src: 'grusha_juice.png',
					alt: 'Сок#3'
				},
				caption: 'Яблоко-груша',
				ml: '200',
				description: 'Состав: яблочный сок, пюре из груш, витаминный комплекс «Active Kid» (B2, B3, B5, B12)',
				vitamines: {
					0: {
						'caption': 'b2',
						'value': '16%',
						'color': '#44C7F4',
						'fontSize': '27px',
					},
					1: {
						'caption': 'b3',
						'value': '24%',
						'color': '#61A2D7',
						'fontSize': '27px',
					},
					2: {
						'caption': 'b5',
						'value': '32%',
						'color': '#A6CF38',
						'fontSize': '27px',
					},
					3: {
						'caption': 'b12',
						'value': '24%',
						'color': '#39B54A',
						'fontSize': '27px',
					},
					4: {
						'caption': 'ККАл/\nКДЖ',
						'value': '80/340',
						'color': '#1D5533',
						'fontSize': '14px',
					},
				},
			},
	],
		productsDrink: [
			{
				type: 'drink',
				key: 1,
				image: {
					src: 'juice-type-1.png',
					alt: 'Сок#1'
				},
				caption: 'Яблоко-апельсин-манго',
				ml: '330',
				description: 'Состав: яблочный сок, апельсиновый сок, пюре из манго, витаминный комплекс «Active Kid» (B2, B3, B5, B12)',
				vitamines: {
					0: {
						'caption': 'b2',
						'value': '26%',
						'color': '#44C7F4',
						'fontSize': '27px',
					},
					1: {
						'caption': 'b3',
						'value': '20%',
						'color': '#61A2D7',
						'fontSize': '27px',
					},
					2: {
						'caption': 'b5',
						'value': '36%',
						'color': '#A6CF38',
						'fontSize': '27px',
					},
					3: {
						'caption': 'b12',
						'value': '13%',
						'color': '#39B54A',
						'fontSize': '27px',
					},
					4: {
						'caption': 'ККАл/\nКДЖ',
						'value': '73/310',
						'color': '#1D5533',
						'fontSize': '14px',
					},
				},
			},
			{
				type: 'drink',
				key: 3,
				image: {
					src: 'about_product_1_2.png',
					alt: 'Сок#2'
				},
				caption: 'Яблоко-Клубника-Киви',
				ml: '200',
				description: 'Состав: яблочный сок, пюре из груш, витаминный комплекс «Active Kid» (B2, B3, B5, B12)',
				vitamines: {
					0: {
						'caption': 'b2',
						'value': '23%',
						'color': '#44C7F4',
						'fontSize': '27px',
					},
					1: {
						'caption': 'b3',
						'value': '21%',
						'color': '#61A2D7',
						'fontSize': '27px',
					},
					2: {
						'caption': 'b5',
						'value': '36%',
						'color': '#A6CF38',
						'fontSize': '27px',
					},
					3: {
						'caption': 'b12',
						'value': '13%',
						'color': '#39B54A',
						'fontSize': '27px',
					},
					4: {
						'caption': 'ККАл/\nКДЖ',
						'value': '73/310',
						'color': '#1D5533',
						'fontSize': '14px',
					},
				},
			},
			{
				type: 'drink',
				key: 4,
				image: {
					src: 'about_product_1_3.png',
					alt: 'Сок#2'
				},
				caption: 'Яблоко-Малина-Арбуз',
				ml: '200',
				description: 'Состав: яблочный сок, пюре из груш, витаминный комплекс «Active Kid» (B2, B3, B5, B12)',
				vitamines: {
					0: {
						'caption': 'b2',
						'value': '29%',
						'color': '#44C7F4',
						'fontSize': '27px',
					},
					1: {
						'caption': 'b3',
						'value': '22%',
						'color': '#61A2D7',
						'fontSize': '27px',
					},
					2: {
						'caption': 'b5',
						'value': '35%',
						'color': '#A6CF38',
						'fontSize': '27px',
					},
					3: {
						'caption': 'b12',
						'value': '13%',
						'color': '#39B54A',
						'fontSize': '27px',
					},
					4: {
						'caption': 'ККАл/\nКДЖ',
						'value': '73/310',
						'color': '#1D5533',
						'fontSize': '14px',
					},
				},
			},
		],
		about: [
			{
				key: 1,
				image: {
					src: 'about_product_1_5.png',
					alt: 'Сок#1',
					caption: 'яблоко-вишня',
				},
			},
			{
				key: 2,
				image: {
					src: 'about_product_1_4.png',
					alt: 'Сок#2',
					caption: 'яблоко-апельсин-манго',
				},
			},
			{
				key: 3,
				image: {
					src: 'about_product_1_3.png',
					alt: 'Сок#3',
					caption: 'яблоко-малина-арбуз',
				},
			},
			{
				key: 4,
				image: {
					src: 'about_product_1_2.png',
					alt: 'Сок#4',
					caption: 'яблоко-клубника-киви',
				},
			},
			{
				key: 15,
				image: {
					src: 'about_product_1_1.png',
					alt: 'Сок#5',
					caption: 'яблоко',
				},
			},
		],
		merch: [
		{
			key: 1,
			image: {
				src: 'prize-t-shirt.png',
				alt: 'Футболка'
			},
			caption: '6 баллов'
		},
		{
			key: 2,
			image: {
				src: 'merch1.png',
				alt: 'Шапка'
			},
			caption: '9 баллов'
		},
		{
			key: 3,
			image: {
				src: 'merch3 1.png',
				alt: 'Худи'
			},
			caption: '12 баллов'
		}
	],
		winners: [
		{
			key: 1,
			image: {
				src: 'person_1.png',
				alt: 'person_1'
			},
		},
		{
			key: 2,
			image: {
				src: 'person_2.png',
				alt: 'person_2'
			},
		},
		{
			key: 3,
			image: {
				src: 'person_3.png',
				alt: 'person_3'
			},
		},
		{
			key: 4,
			image: {
				src: 'person_4.png',
				alt: 'person_4'
			},
		}
	],
		faq: [
		{
			key: 1,
			image: {
				src: 'cart.svg',
				alt: 'Корзина'
			},
			caption1: 'Покупай',
			caption2: '«Добрый»* и регистрируй чеки'
		},
		{
			key: 2,
			image: {
				src: 'rubles.svg',
				alt: 'Рубли'
			},
			caption1: 'Получай',
			caption2: 'Гарантированно 25 на телефон'
		},
		{
			key: 3,
			image: {
				src: 'points.svg',
				alt: 'Баллы'
			},
			caption1: 'Копи баллы',
			caption2: null
		},
		{
			key: 4,
			image: {
				src: 'cart.svg',
				alt: ''
			},
			caption1: 'Получай и выигрывай призы',
			caption2: null
		}
	]
}